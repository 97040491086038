*, :before, :after {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: system-ui, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
  line-height: 1.15;
}

body {
  margin: 0;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  border-color: currentColor;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

legend {
  padding: 0;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

* {
  border: 0;
  margin: 0;
  padding: 0;
}

:focus {
  text-overflow: ellipsis;
  zoom: 1;
  outline: none;
}

.preload * {
  transition: none !important;
}

html {
  font-display: swap;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  text-shadow: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: #0000;
  -webkit-tap-highlight-color: transparent;
  font: 400 100% / 1.45 Inter, ui-sans-serif, system-ui, -apple-system, blinkmacsystemfont, Segoe UI, roboto, Helvetica Neue, arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

@supports (font-variation-settings: normal) {
  html {
    font: 400 100% / 1.45 Inter var, Inter, ui-sans-serif, system-ui, -apple-system, blinkmacsystemfont, Segoe UI, roboto, Helvetica Neue, arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  }
}

body {
  color: oklch(20.77% .04 265.75);
  font-feature-settings: "liga", "calt", "ccmp", "kern", "case", "ss04", "cv02", "cv03", "cv04";
  letter-spacing: -.011em;
  text-overflow: ellipsis;
  font-variant-ligatures: common-ligatures;
  min-width: 20em;
  text-rendering: optimizelegibility;
  scroll-behavior: smooth;
  background-color: oklch(20.77% .04 265.75);
  font-size: 1em;
  overflow-y: auto;
}

h1 {
  text-align: center;
  font-family: Inter, ui-sans-serif, system-ui, -apple-system, blinkmacsystemfont, Segoe UI, roboto, Helvetica Neue, arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

@supports (font-variation-settings: normal) {
  h1 {
    font-family: Inter var, Inter, ui-sans-serif, system-ui, -apple-system, blinkmacsystemfont, Segoe UI, roboto, Helvetica Neue, arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  }
}

h2 {
  text-align: center;
  font-family: Inter, ui-sans-serif, system-ui, -apple-system, blinkmacsystemfont, Segoe UI, roboto, Helvetica Neue, arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

@supports (font-variation-settings: normal) {
  h2 {
    font-family: Inter var, Inter, ui-sans-serif, system-ui, -apple-system, blinkmacsystemfont, Segoe UI, roboto, Helvetica Neue, arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  }
}

h1 {
  letter-spacing: -.025em;
  text-transform: capitalize;
  margin: 1.25em 0 .45em;
  font-size: 5em;
  font-weight: 800;
  line-height: 1;
}

h2 {
  letter-spacing: -.022em;
  text-transform: uppercase;
  margin: 1.15em 0 .6em;
  font-size: 2em;
  font-weight: 700;
  line-height: 1.25;
}

@supports ((-webkit-background-clip: text) or (background-clip: text)) {
  h1 span {
    background-image: linear-gradient(to right in oklch, #1e293b, #312e8100 44%, #312e8100 56%, #1e293b), url("noise-indigo-500.7b2e0d15.png"), linear-gradient(to right in oklch, #1e293b, #312e81 44%, #312e81 56%, #1e293b);
    background-image: linear-gradient(to right in oklch, #1e293b, #312e8100 44%, #312e8100 56%, #1e293b), url("noise-indigo-500.7b2e0d15.png"), linear-gradient(to right in oklch, #1e293b, #312e81 44%, #312e81 56%, #1e293b);
    color: #0000;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    -webkit-background-clip: text;
    background-clip: text;
  }

  @supports (color: lab(0% 0 0)) {
    h1 span {
      background-image: linear-gradient(to right in oklch, #1e293b, lab(23.581% 22.2368 -47.4813 / 0) 44%, lab(23.581% 22.2368 -47.4813 / 0) 56%, #1e293b), url("noise-indigo-500.7b2e0d15.png"), linear-gradient(to right in oklch, #1e293b, #312e81 44%, #312e81 56%, #1e293b);
      background-image: linear-gradient(to right in oklch, lab(16.2229% -.470415 -13.2791), lab(23.5971% 22.1194 -47.3362 / 0) 44%, lab(23.5971% 22.1194 -47.3362 / 0) 56%, lab(16.2229% -.470415 -13.2791)), url("noise-indigo-500.7b2e0d15.png"), linear-gradient(to right in oklch, lab(16.2229% -.470415 -13.2791), lab(23.5971% 22.1194 -47.3362) 44%, lab(23.5971% 22.1194 -47.3362) 56%, lab(16.2229% -.470415 -13.2791));
    }
  }
}

hr {
  background-image: linear-gradient(to left in oklch, #818cf800, #818cf8, #818cf800), linear-gradient(to left in oklch, #bfdbfe00, #bfdbfe57, #bfdbfe00), linear-gradient(to left in oklch, #f0f9ff00, #f0f9ff21, #f0f9ff00);
  background-image: linear-gradient(to left in oklch, #818cf800, #818cf8, #818cf800), linear-gradient(to left in oklch, #bfdbfe00, #bfdbfe57, #bfdbfe00), linear-gradient(to left in oklch, #f0f9ff00, #f0f9ff21, #f0f9ff00);
  background-position: center, center, center;
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-size: 100% .0625rem, 60% .1875rem, 20% 100%;
  border: 0;
  height: .3125rem;
  margin: 3em auto 2em;
  padding: 0 2em;
}

@supports (color: lab(0% 0 0)) {
  hr {
    background-image: linear-gradient(to left in oklch, lab(61.1112% 17.7252 -56.2773 / 0), lab(61.1112% 17.7252 -56.2773), lab(61.1112% 17.7252 -56.2773 / 0)), linear-gradient(to left in oklch, lab(86.1973% -3.95069 -20.4006 / 0), lab(86.1973% -3.95069 -20.4006 / .34), lab(86.1973% -3.95069 -20.4006 / 0)), linear-gradient(to left in oklch, lab(97.3737% -2.24718 -3.96847 / 0), lab(97.3737% -2.24718 -3.96847 / .13), lab(97.3737% -2.24718 -3.96847 / 0));
    background-image: linear-gradient(to left in oklch, lab(61.1148% 17.6664 -56.1732 / 0), lab(61.1148% 17.6664 -56.1732), lab(61.1148% 17.6664 -56.1732 / 0)), linear-gradient(to left in oklch, lab(86.1924% -3.9472 -20.3808 / 0), lab(86.1924% -3.9472 -20.3808 / .34), lab(86.1924% -3.9472 -20.3808 / 0)), linear-gradient(to left in oklch, lab(97.3717% -2.16118 -3.8148 / 0), lab(97.3717% -2.16118 -3.8148 / .13), lab(97.3717% -2.16118 -3.8148 / 0));
  }
}

p {
  letter-spacing: -.017em;
  widows: 3;
  orphans: 3;
  margin-bottom: 1.5em;
  font-size: 1.25em;
  overflow: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

a, i, b, em, strong, abbr {
  font-size: inherit;
}

i, em, address, small {
  font-family: inherit;
  font-style: inherit;
}

abbr {
  font-variant: small-caps;
  font-feature-settings: "smcp" 1, "c2sc" 1;
  text-transform: uppercase;
  letter-spacing: .025em;
  word-spacing: .025em;
  font-family: inherit;
  font-style: normal;
}

abbr:hover {
  cursor: help;
}

abbr[title] {
  text-decoration-color: lab(74.1865% 7.27969 -37.7407);
}

input, textarea, button {
  font-size: inherit;
  appearance: none;
  border-radius: .625rem;
  margin: .5em auto 1.5em;
  padding: .5em 1em;
  line-height: 1.5;
  display: block;
}

input {
  background-color: oklch(92.88% .013 255.51);
  border: .125rem solid lab(98.1665% -.3694 -1.0597 / .34);
  min-width: 100%;
  font-family: inherit;
  box-shadow: 0 0 .125rem oklch(12.88% .041 264.7 / .08), inset 0 0 .25rem oklch(12.88% .041 264.7 / .05);
}

input::placeholder {
  color: oklch(71.07% .035 256.79 / .89);
}

textarea {
  background-color: oklch(92.88% .013 255.51);
  border: .125rem solid lab(98.1665% -.3694 -1.0597 / .34);
  min-width: 100%;
  font-family: inherit;
  box-shadow: 0 0 .125rem oklch(12.88% .041 264.7 / .08), inset 0 0 .25rem oklch(12.88% .041 264.7 / .05);
}

textarea::placeholder {
  color: oklch(71.07% .035 256.79 / .89);
}

textarea {
  resize: none;
  field-sizing: content;
  min-height: 9em;
  overflow: hidden;
}

label {
  font-weight: 600;
}

img, svg, .avatar {
  perspective: 1000px;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
}

a:link, button, .button {
  cursor: pointer;
}

button {
  letter-spacing: -.011em;
  white-space: nowrap;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  min-width: 8.25em;
  font-family: Inter, ui-sans-serif, system-ui, -apple-system, blinkmacsystemfont, Segoe UI, roboto, Helvetica Neue, arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-weight: 600;
  transition: background .89s, color .89s, box-shadow .13s ease-out;
}

button:focus {
  outline-offset: .25rem;
  outline: .0625rem dotted oklch(97.05% .014 254.6 / .34);
}

@supports (font-variation-settings: normal) {
  button {
    font-family: Inter var, Inter, ui-sans-serif, system-ui, -apple-system, blinkmacsystemfont, Segoe UI, roboto, Helvetica Neue, arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  }
}

.button {
  letter-spacing: -.011em;
  white-space: nowrap;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  min-width: 8.25em;
  font-family: Inter, ui-sans-serif, system-ui, -apple-system, blinkmacsystemfont, Segoe UI, roboto, Helvetica Neue, arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-weight: 600;
  transition: background .89s, color .89s, box-shadow .13s ease-out;
}

.button:focus {
  outline-offset: .25rem;
  outline: .0625rem dotted oklch(97.05% .014 254.6 / .34);
}

@supports (font-variation-settings: normal) {
  .button {
    font-family: Inter var, Inter, ui-sans-serif, system-ui, -apple-system, blinkmacsystemfont, Segoe UI, roboto, Helvetica Neue, arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  }
}

button {
  color: oklch(27.91% .074 45.64);
  background-color: oklch(83.69% .164 84.43);
  position: relative;
  box-shadow: 0 -.0625rem .0625rem -.0625rem oklch(46.98% .143 37.3 / .55), 0 .0625rem .0625rem -.0625rem oklch(27.91% .074 45.64 / .55), inset 0 -.5rem .5rem oklch(41.37% .105 45.9 / .34), inset 0 .5rem .5rem oklch(96.19% .058 95.62 / .34);
}

button:before {
  content: "";
  z-index: -1;
  background: linear-gradient(to bottom in oklch, #fde68a8c, #d977088c);
  background: linear-gradient(to bottom in oklch, #fde68a8c, #d977088c);
  backdrop-filter: blur(.75rem);
  border-radius: .75rem;
  margin: -.125rem;
  position: absolute;
  inset: 0;
  box-shadow: 0 0 .125rem oklch(27.91% .074 45.64 / .21);
}

@supports (color: lab(0% 0 0)) {
  button:before {
    background: linear-gradient(to bottom in oklch, lab(91.7397% -.63315 47.6 / .55), lab(60.4961% 34.9003 66.1485 / .55));
    background: linear-gradient(to bottom in oklch, lab(91.7421% -.639319 47.5398 / .55), lab(60.4912% 34.7761 66.1715 / .55));
  }
}

@media (hover: hover) {
  button:hover {
    color: oklch(41.37% .105 45.9);
    box-shadow: 0 -.0625rem .0625rem -.0625rem oklch(46.98% .143 37.3 / .55), 0 .0625rem .0625rem -.0625rem oklch(27.91% .074 45.64 / .55), inset 0 -.5rem .5rem oklch(83.69% .164 84.43 / 0), inset 0 .5rem .5rem oklch(83.69% .164 84.43 / 0);
  }

  button:hover:before {
    background: linear-gradient(to bottom in oklch, #fcd34e8c, #f59e098c);
    background: linear-gradient(to bottom in oklch, #fcd34e8c, #f59e098c);
  }

  @supports (color: lab(0% 0 0)) {
    button:hover:before {
      background: linear-gradient(to bottom in oklch, lab(86.4142% 4.96843 68.2247 / .55), lab(72.7889% 26.921 75.6657 / .55));
      background: linear-gradient(to bottom in oklch, lab(86.4121% 4.89742 68.3542 / .55), lab(72.7908% 27.0037 75.6884 / .55));
    }
  }
}

button:focus {
  color: oklch(37.91% .138 265.52);
  background-color: oklch(90.52% .166 98.11);
  box-shadow: 0 -.0625rem .0625rem -.0625rem oklch(46.98% .143 37.3 / .55), 0 .0625rem .0625rem -.0625rem oklch(27.91% .074 45.64 / .55), inset 0 -.5rem .5rem oklch(90.52% .166 98.11 / 0), inset 0 .5rem .5rem oklch(90.52% .166 98.11 / 0);
}

button:focus:before {
  background: linear-gradient(to bottom in oklch, #93c5fd8c, #3b82f68c);
  background: linear-gradient(to bottom in oklch, #93c5fd8c, #3b82f68c);
}

@supports (color: lab(0% 0 0)) {
  button:focus:before {
    background: linear-gradient(to bottom in oklch, lab(77.5627% -6.25411 -33.2536 / .55), lab(54.6189% 8.76281 -65.7907 / .55));
    background: linear-gradient(to bottom in oklch, lab(77.5643% -6.2663 -33.3886 / .55), lab(54.6214% 8.755 -65.7847 / .55));
  }
}

@media (hover: hover) {
  button:focus:hover {
    color: oklch(48.82% .217 264.38);
  }

  button:focus:hover:before {
    background: oklch(71.37% .143 254.62 / .89);
  }
}

button:focus:active {
  color: oklch(48.82% .217 264.38);
}

button:focus:active:before {
  background: oklch(71.37% .143 254.62 / .89);
}

button:focus:active {
  box-shadow: 0 -.0625rem .0625rem -.0625rem oklch(46.98% .143 37.3 / .55), 0 .0625rem .0625rem -.0625rem oklch(27.91% .074 45.64 / .55), inset 0 .25rem .25rem oklch(40.5% .096 131.06 / .34), inset 0 -.25rem .25rem oklch(95.63% .044 203.39 / .34);
}

button:active {
  color: oklch(47.32% .125 46.2);
  box-shadow: 0 -.0625rem .0625rem -.0625rem oklch(46.98% .143 37.3 / 0), 0 .0625rem .0625rem -.0625rem oklch(27.91% .074 45.64 / 0), inset 0 .25rem .25rem oklch(41.37% .105 45.9 / .34), inset 0 -.25rem .25rem oklch(96.19% .058 95.62 / .34);
}

button:active:before {
  background: oklch(87.9% .153 91.61 / .55);
}

[data-hover]:hover {
  visibility: hidden;
}

[data-hover]:hover:after {
  content: attr(data-hover);
  visibility: visible;
  position: absolute;
  left: 0;
}

.emoji {
  font-feature-settings: normal;
  font-family: Noto Color Emoji, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, sans-serif;
}

.emoji.pulsate {
  color: oklch(58.58% .222 17.58);
  animation: .365s infinite alternate heart-beat;
  display: inline-block;
}

.confetti {
  cursor: pointer;
}

#page {
  background-color: oklch(92.88% .013 255.51);
  background-image: radial-gradient(clamp(15em, 56vw, 34em) at bottom, oklch(78.53% .104 274.71), #a7b9fb, #adc0fb, #b3c7fa, #b9cdf8, #c0d2f7, #c7d8f5, #d2dff4, #d9e3f2, #e1e7ef, oklch(92.88% .013 255.51));
  border-radius: .625rem;
  margin: 0 auto;
}

#contact {
  backdrop-filter: blur(.75rem);
  z-index: 1;
  background-color: oklch(86.9% .02 252.89 / .34);
  background-image: repeating-linear-gradient(to top, #0000 0 .24rem, oklch(92.88% .013 255.51 / .13) .24rem .48rem);
  border: .0625rem solid lab(98.1665% -.3694 -1.0597 / .34);
  border-radius: .625rem;
  max-width: 28em;
  margin: .125rem auto 3rem;
  padding: 1em 2em;
  position: relative;
  box-shadow: 0 0 .125rem oklch(12.88% .041 264.7 / .13);
}

@media (width <= 30em) {
  #contact {
    max-width: inherit;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
  }
}

section {
  z-index: 2;
  padding: 3em 2em;
  position: relative;
}

section a:link {
  color: oklch(48.82% .217 264.38);
  border: .125rem solid #0000;
  border-radius: calc(.725em + .125rem);
  margin: -.125rem;
  text-decoration: underline;
}

@media (hover: hover) {
  section a:link:hover {
    color: oklch(92.88% .013 255.51);
    background-color: oklch(48.82% .217 264.38);
    border-color: oklch(48.82% .217 264.38);
    text-decoration-color: #0000;
  }
}

section a:link:focus {
  border: .125rem solid lab(37.6624% 26.9737 -76.4394 / .55);
  text-decoration-color: #0000;
}

section a:link:active {
  color: oklch(92.88% .013 255.51);
  background-color: oklch(48.82% .217 264.38);
  border-color: oklch(48.82% .217 264.38);
  text-decoration-color: #0000;
}

section a:visited {
  color: oklch(48.82% .217 264.38);
  border: .125rem solid #0000;
  border-radius: calc(.725em + .125rem);
  margin: -.125rem;
  text-decoration: underline;
}

@media (hover: hover) {
  section a:visited:hover {
    color: oklch(92.88% .013 255.51);
    background-color: oklch(48.82% .217 264.38);
    border-color: oklch(48.82% .217 264.38);
    text-decoration-color: #0000;
  }
}

section a:visited:focus {
  border: .125rem solid lab(37.6624% 26.9737 -76.4394 / .55);
  text-decoration-color: #0000;
}

section a:visited:active {
  color: oklch(92.88% .013 255.51);
  background-color: oklch(48.82% .217 264.38);
  border-color: oklch(48.82% .217 264.38);
  text-decoration-color: #0000;
}

article {
  max-width: 42em;
  margin: 0 auto;
}

footer {
  color: oklch(92.88% .013 255.51);
  text-align: center;
  white-space: nowrap;
  z-index: 2;
  padding: 1em;
  position: relative;
}

footer ul {
  text-align: center;
  margin: .75em 0 -1.25em;
}

footer ul li {
  display: inline;
}

footer button {
  letter-spacing: -.011em;
  white-space: nowrap;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  font-family: Inter, ui-sans-serif, system-ui, -apple-system, blinkmacsystemfont, Segoe UI, roboto, Helvetica Neue, arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-weight: 600;
  font-size: inherit;
  color: oklch(98.42% .003 247.86);
  backdrop-filter: blur(.75rem);
  background: oklch(98.42% .003 247.86 / .08);
  border-radius: .625rem;
  width: 6.5em;
  margin: 0 .5em;
  padding: .5em;
  line-height: 1.5;
  display: inline-block;
  box-shadow: inset 0 .25rem 0 -.1875rem oklch(96.83% .007 247.9 / .13), inset 0 -.25rem 0 -.1875rem oklch(71.07% .035 256.79 / .13);
}

@media (hover: hover) {
  footer button:hover {
    color: oklch(96.83% .007 247.9);
    background: oklch(98.42% .003 247.86 / .13);
    box-shadow: inset 0 .25rem 0 -.1875rem oklch(98.42% .003 247.86 / .34), inset 0 -.25rem 0 -.1875rem oklch(86.9% .02 252.89 / .34);
  }
}

footer button:focus {
  background: oklch(71.37% .143 254.62 / .08);
  box-shadow: inset 0 .25rem 0 -.1875rem oklch(80.91% .096 251.81 / .13), inset 0 -.25rem 0 -.1875rem oklch(62.31% .188 259.81 / .13);
}

@media (hover: hover) {
  footer button:focus:hover {
    background: oklch(71.37% .143 254.62 / .13);
    box-shadow: inset 0 .25rem 0 -.1875rem oklch(88.23% .057 254.13 / .34), inset 0 -.25rem 0 -.1875rem oklch(71.37% .143 254.62 / .34);
  }
}

footer button:focus:active {
  background: oklch(71.37% .143 254.62 / .13);
  box-shadow: inset 0 .25rem 0 -.1875rem oklch(88.23% .057 254.13 / .34), inset 0 -.25rem 0 -.1875rem oklch(71.37% .143 254.62 / .34), inset 0 .25rem 1rem oklch(97.05% .014 254.6 / .08), inset 0 -.25rem 1rem oklch(97.05% .014 254.6 / .08);
}

footer button:active {
  color: oklch(92.88% .013 255.51);
  background: oklch(98.42% .003 247.86 / .13);
  box-shadow: inset 0 .25rem 0 -.1875rem oklch(98.42% .003 247.86 / .34), inset 0 -.25rem 0 -.1875rem oklch(86.9% .02 252.89 / .34), inset 0 .25rem 1rem oklch(98.42% .003 247.86 / .08), inset 0 -.25rem 1rem oklch(98.42% .003 247.86 / .08);
}

@supports (font-variation-settings: normal) {
  footer button {
    font-family: Inter var, Inter, ui-sans-serif, system-ui, -apple-system, blinkmacsystemfont, Segoe UI, roboto, Helvetica Neue, arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  }
}

footer .button {
  letter-spacing: -.011em;
  white-space: nowrap;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  font-family: Inter, ui-sans-serif, system-ui, -apple-system, blinkmacsystemfont, Segoe UI, roboto, Helvetica Neue, arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-weight: 600;
  font-size: inherit;
  color: oklch(98.42% .003 247.86);
  backdrop-filter: blur(.75rem);
  background: oklch(98.42% .003 247.86 / .08);
  border-radius: .625rem;
  width: 6.5em;
  margin: 0 .5em;
  padding: .5em;
  line-height: 1.5;
  display: inline-block;
  box-shadow: inset 0 .25rem 0 -.1875rem oklch(96.83% .007 247.9 / .13), inset 0 -.25rem 0 -.1875rem oklch(71.07% .035 256.79 / .13);
}

@media (hover: hover) {
  footer .button:hover {
    color: oklch(96.83% .007 247.9);
    background: oklch(98.42% .003 247.86 / .13);
    box-shadow: inset 0 .25rem 0 -.1875rem oklch(98.42% .003 247.86 / .34), inset 0 -.25rem 0 -.1875rem oklch(86.9% .02 252.89 / .34);
  }
}

footer .button:focus {
  background: oklch(71.37% .143 254.62 / .08);
  box-shadow: inset 0 .25rem 0 -.1875rem oklch(80.91% .096 251.81 / .13), inset 0 -.25rem 0 -.1875rem oklch(62.31% .188 259.81 / .13);
}

@media (hover: hover) {
  footer .button:focus:hover {
    background: oklch(71.37% .143 254.62 / .13);
    box-shadow: inset 0 .25rem 0 -.1875rem oklch(88.23% .057 254.13 / .34), inset 0 -.25rem 0 -.1875rem oklch(71.37% .143 254.62 / .34);
  }
}

footer .button:focus:active {
  background: oklch(71.37% .143 254.62 / .13);
  box-shadow: inset 0 .25rem 0 -.1875rem oklch(88.23% .057 254.13 / .34), inset 0 -.25rem 0 -.1875rem oklch(71.37% .143 254.62 / .34), inset 0 .25rem 1rem oklch(97.05% .014 254.6 / .08), inset 0 -.25rem 1rem oklch(97.05% .014 254.6 / .08);
}

footer .button:active {
  color: oklch(92.88% .013 255.51);
  background: oklch(98.42% .003 247.86 / .13);
  box-shadow: inset 0 .25rem 0 -.1875rem oklch(98.42% .003 247.86 / .34), inset 0 -.25rem 0 -.1875rem oklch(86.9% .02 252.89 / .34), inset 0 .25rem 1rem oklch(98.42% .003 247.86 / .08), inset 0 -.25rem 1rem oklch(98.42% .003 247.86 / .08);
}

@supports (font-variation-settings: normal) {
  footer .button {
    font-family: Inter var, Inter, ui-sans-serif, system-ui, -apple-system, blinkmacsystemfont, Segoe UI, roboto, Helvetica Neue, arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  }
}

@media (width <= 30em) {
  footer button, footer .button {
    text-align: center;
    margin: auto;
    line-height: 1.5;
    display: block;
  }

  footer ul {
    margin-top: -.5em;
  }

  footer ul li {
    margin: 1em;
    display: block;
  }
}

address {
  text-align: center;
  white-space: nowrap;
}

address + small {
  font-size: inherit;
}

main {
  overflow: overlay;
}

header {
  position: relative;
}

#scene {
  background: linear-gradient(to top in oklch, #e2e8f0, #e2e8f000), radial-gradient(circle farthest-side at 21% top, #fb7185, #fda4afe3, #fecdd38c, #fecdd300), radial-gradient(circle farthest-side at right 34%, #fb923c, #fdba74e3, #fed7aa8c, #fed7aa00);
  background: linear-gradient(to top in oklch, #e2e8f0, #e2e8f000), radial-gradient(circle farthest-side at 21% top, #fb7185, #fda4afe3, #fecdd38c, #fecdd300), radial-gradient(circle farthest-side at right 34%, #fb923c, #fdba74e3, #fed7aa8c, #fed7aa00);
  border-radius: .625rem;
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  overflow: hidden;
}

@supports (color: lab(0% 0 0)) {
  #scene {
    background: linear-gradient(to top in oklch, #e2e8f0, lab(91.7085% -.971079 -4.63063 / 0)), radial-gradient(circle farthest-side at 21% top, #fb7185, lab(76.7194% 34.8111 8.75541 / .89), lab(86.8819% 18.5029 3.92846 / .55), lab(86.8819% 18.5029 3.92846 / 0)), radial-gradient(circle farthest-side at right 34%, #fb923c, lab(80.802% 19.4422 45.1659 / .89), lab(88.5448% 9.48662 27.6058 / .55), lab(88.5448% 9.48662 27.6058 / 0));
    background: linear-gradient(to top in oklch, lab(91.7121% -.998646 -4.76968), lab(91.7121% -.998646 -4.76968 / 0)), radial-gradient(circle farthest-side at 21% top, lab(65.5344% 54.9775 16.5129), lab(76.7248% 34.7754 8.74721 / .89), lab(86.8754% 18.5325 3.93454 / .55), lab(86.8754% 18.5325 3.93454 / 0)), radial-gradient(circle farthest-side at right 34%, lab(71.1028% 36.1077 60.7983), lab(80.8032% 19.5329 45.3733 / .89), lab(88.5497% 9.49305 27.6265 / .55), lab(88.5497% 9.49305 27.6265 / 0));
  }
}

@supports (filter: blur(1rem)) or (transform: scale(2)) {
  #scene {
    display: block;
  }
}

#scene:after {
  content: "";
  background-color: oklch(92.88% .013 255.51 / .72);
  width: 100%;
  height: 100%;
  position: absolute;
}

.avatar-frame:before {
  background: linear-gradient(to bottom in oklch, #fecdd357, #fed7aa57);
  background: linear-gradient(to bottom in oklch, #fecdd357, #fed7aa57);
  backdrop-filter: blur(.75rem);
  content: "";
  border-radius: 50%;
  width: 12.5rem;
  height: 12.5rem;
  margin-top: .75rem;
  margin-left: -6.25rem;
  position: absolute;
  left: 50%;
  box-shadow: 0 0 .125rem oklch(12.88% .041 264.7 / .13);
}

@supports (color: lab(0% 0 0)) {
  .avatar-frame:before {
    background: linear-gradient(to bottom in oklch, lab(86.8819% 18.5029 3.92846 / .34), lab(88.5448% 9.48662 27.6058 / .34));
    background: linear-gradient(to bottom in oklch, lab(86.8754% 18.5325 3.93454 / .34), lab(88.5497% 9.49305 27.6265 / .34));
  }
}

@media (width <= 40em) {
  .avatar-frame:before {
    width: 10.5rem;
    height: 10.5rem;
    margin-top: .583333rem;
    margin-left: -5.25rem;
  }
}

.avatar {
  border-radius: 0 6rem 6rem;
  width: 12rem;
  height: 13rem;
  margin: 4rem auto -3rem;
  position: relative;
  overflow: hidden;
}

@media (width <= 40em) {
  .avatar {
    border-radius: 0 5rem 5rem;
    width: 10rem;
    height: 10.8333rem;
    margin: 4.16667rem auto -3rem;
  }
}

.avatar-image {
  background: linear-gradient(140deg in oklch, #fecdd3, #fed7aa);
  background: linear-gradient(140deg in oklch, #fecdd3, #fed7aa);
  border-radius: 50%;
  width: 12rem;
  height: 12rem;
  position: absolute;
  bottom: 0;
  box-shadow: inset 0 -.0625rem .0625rem -.0625rem oklch(45.87% .17 3.82 / .55), inset -.0625rem 0 .0625rem -.0625rem oklch(45.87% .17 3.82 / .21), inset 0 .0625rem .0625rem -.0625rem oklch(26.59% .076 36.26 / .55), inset .0625rem 0 .0625rem -.0625rem oklch(26.59% .076 36.26 / .21);
}

@supports (color: lab(0% 0 0)) {
  .avatar-image {
    background: linear-gradient(140deg in oklch, lab(86.8754% 18.5325 3.93454), lab(88.5497% 9.49305 27.6265));
  }
}

.avatar-image img {
  border-radius: 0 50% 50%;
  width: 13rem;
  height: 13rem;
  position: absolute;
  bottom: 0;
  left: -.5rem;
}

@media (width <= 40em) {
  .avatar-image img {
    width: 10.8333rem;
    height: 10.8333rem;
    left: -.416667rem;
  }
}

.avatar-image:after {
  border-radius: 0 50% 50%;
  width: 13rem;
  height: 13rem;
  position: absolute;
  bottom: 0;
  left: -.5rem;
}

@media (width <= 40em) {
  .avatar-image:after {
    width: 10.8333rem;
    height: 10.8333rem;
    left: -.416667rem;
  }
}

.avatar-image:after {
  content: "";
  box-shadow: inset 0 -.125rem .125rem -.125rem oklch(12.88% .041 264.7 / .89);
}

@media (width <= 40em) {
  .avatar-image {
    width: 10rem;
    height: 10rem;
  }
}

#header-background {
  filter: blur(.75rem);
  margin: 0 -6rem;
  position: absolute;
  top: 3em;
  left: 50%;
}

#header-background .avatar-image {
  background: linear-gradient(140deg in oklch, #fda4af, #fdba74);
  background: linear-gradient(140deg in oklch, #fda4af, #fdba74);
  box-shadow: none;
}

@supports (color: lab(0% 0 0)) {
  #header-background .avatar-image {
    background: linear-gradient(140deg in oklch, lab(76.7248% 34.7754 8.74721), lab(80.8032% 19.5329 45.3733));
  }
}

#header-background .avatar-image:after {
  box-shadow: none;
}

#header-background .avatar {
  transform-origin: center;
  transform: scale(2.5);
}

@media (width <= 40em) {
  #header-background {
    margin: 0 -5rem;
  }
}

#logofoot-wrapper {
  backdrop-filter: blur(.75rem);
  fill: oklch(98.42% .003 247.86);
  background: oklch(12.88% .041 264.7 / .05);
  border-radius: 50%;
  width: 12rem;
  height: 12rem;
  margin: 1.5rem auto;
  position: relative;
}

#logofoot {
  object-fit: cover;
  border-radius: 50%;
  width: 6rem;
  height: 6rem;
  position: absolute;
  inset: 3rem;
}

#logofoot-ring {
  text-transform: uppercase;
  font-size: .875rem;
  font-weight: 300;
  line-height: 1;
  animation: 20s linear infinite reverse spin;
  overflow: visible;
}

#logofoot-ring .tspan-space {
  opacity: .4;
}

#logofoot-ring .tspan-bullet {
  fill: oklch(57.71% .215 27.33);
}

#logofoot-ring .tspan-overline {
  text-decoration-line: overline;
}

#body {
  overflow: overlay;
  background-color: oklch(20.77% .04 265.75);
  background-image: radial-gradient(56vw at 50% calc(100% - 11.5rem), oklch(35.88% .135 278.7 / 0), oklch(28.23% .087 267.94 / .13), oklch(20.77% .04 265.75)), url("noise-indigo-500.7b2e0d15.png"), radial-gradient(56vw at 50% calc(100% - 11.5rem), oklch(35.88% .135 278.7), oklch(28.23% .087 267.94), oklch(20.77% .04 265.75));
  padding: 1em 1em 0;
}

@media (width <= 22em) {
  #body {
    padding-left: 0;
    padding-right: 0;
  }
}

b, strong {
  font-weight: 600;
  line-height: 100%;
}

.tray {
  margin-bottom: 1.5em;
}

.tray li {
  display: block;
}

.tray li span {
  vertical-align: middle;
  white-space: nowrap;
  margin-bottom: 1.5em;
  display: inline-block;
}

.hidden {
  display: none;
}

img[src="Error.src"] {
  visibility: hidden;
}

@keyframes zoom-in {
  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes heart-beat {
  0% {
    transform: scale(1);
  }

  8% {
    transform: scale(.96);
  }

  62% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1.05);
  }
}

@keyframes spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (width <= 40em) {
  .tray {
    margin: 1em 0 2em;
  }

  .tray li span {
    border-radius: 0;
    margin: .5em;
    line-height: 4.5;
    display: inline;
  }

  h1 {
    letter-spacing: -.023em;
    font-size: 3em;
  }

  h2 {
    letter-spacing: -.019em;
    font-size: 1.5em;
  }

  p, dt {
    letter-spacing: -.011em;
    font-size: 1em;
  }

  dd {
    letter-spacing: .01em;
    font-size: .625em;
  }

  article {
    max-width: 32em;
  }

  .avatar-frame:before {
    width: 10.5rem;
    height: 10.5rem;
    margin-top: .583333rem;
    margin-left: -5.25rem;
  }

  @media (width <= 40em) {
    .avatar-frame:before {
      width: 10.5rem;
      height: 10.5rem;
      margin-top: .583333rem;
      margin-left: -5.25rem;
    }
  }

  .avatar {
    width: 10rem;
    height: 10.8333rem;
    margin: 2.16667rem auto -1.8rem;
  }

  @media (width <= 40em) {
    .avatar {
      width: 10rem;
      height: 10.8333rem;
      margin: 2.16667rem auto -1.8rem;
    }
  }

  .avatar-image {
    width: 10rem;
    height: 10rem;
  }

  .avatar-image img {
    width: 10.8333rem;
    height: 10.8333rem;
  }

  @media (width <= 40em) {
    .avatar-image img {
      width: 10.8333rem;
      height: 10.8333rem;
    }
  }

  .avatar-image:after {
    width: 10.8333rem;
    height: 10.8333rem;
  }

  @media (width <= 40em) {
    .avatar-image:after {
      width: 10.8333rem;
      height: 10.8333rem;
    }

    .avatar-image {
      width: 10rem;
      height: 10rem;
    }
  }

  #header-background {
    margin: 0 -5rem;
  }

  #header-background .avatar {
    transform: scale(2);
  }

  @media (width <= 40em) {
    #header-background {
      margin: 0 -5rem;
    }
  }
}

@media (width >= 175em) {
  * {
    letter-spacing: -.016em;
    font-size: 1.2em;
  }

  article {
    max-width: 48em;
  }
}

@media (width >= 300em) {
  * {
    letter-spacing: -.017em;
    font-size: 1.3em;
  }

  article {
    max-width: 52em;
  }
}

@media print {
  aside, form, footer ul, hr, details, #scene, #contact, .emoji, .avatar, .avatar-frame {
    display: none !important;
  }

  a[href]:after {
    color: #333;
    content: " (" attr(href) ") ";
  }

  footer:after {
    color: #333;
    content: "http://cenksoykan.com";
    display: block;
  }

  address:before {
    color: #333;
    content: "hi@cenksoykan.com";
    display: block;
  }

  *, html, body {
    font-size: 10pt;
    color: #000 !important;
    opacity: 1 !important;
    visibility: visible !important;
    background-color: #0000 !important;
    transition-property: none !important;
    transform: none !important;
  }

  h1 {
    font-size: 22pt;
  }

  h2 {
    font-size: 16pt;
  }

  p {
    text-indent: .5em;
    padding-bottom: .5em;
    font-size: 12pt;
  }

  time {
    font-size: 8pt;
    color: #333 !important;
  }

  section {
    padding: 1em;
  }

  article {
    max-width: 88%;
  }

  #logofoot {
    width: 4em;
    height: 4em;
    padding-bottom: 1em;
  }

  #logofoot path {
    fill: #000;
  }
}
/*# sourceMappingURL=index.f768c59a.css.map */
